import React from "react";

const CalendarEvent = ({ event }) => {
  const { from, to, description, title, location } = event;
  const fromTime = from.format("HH:mm");
  const toTime = to.format("HH:mm");

  return (
    <div className="calendarEvent">
      <div className="calendarEventTitleWrapper">
        <div className="calendarEventTitle">{title}</div>
        <div className="calendarEventTimeWrapper">
          <div className="calendarEventFromTime">{fromTime}</div>-
          <div className="calendarEventToTime">{toTime}</div>
        </div>
      </div>
      <div className="calendarEventDetails">
        <div className="calendarEventDescription">{description}</div>
        <div className="calendarEventLocation">{location}</div>
      </div>
    </div>
  );
};

export default CalendarEvent;
