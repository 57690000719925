import React, { Component } from "react";
import withGalleries from "higherOrderComponents/withGalleries";

import { openGalleryModal } from "redux/actions/modalActions";
import store from "redux/store";

class GalleryGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let thumbnailUrl,
      photoId = "";
    photoId = Object.keys(this.props.galleries[this.props.galleryName])[0];
    thumbnailUrl = this.props.galleries[this.props.galleryName][photoId];
    let numberOfPhotos = Object.keys(this.props.galleries[this.props.galleryName]).length;

    return (
      <div className="galleryGroup">
        <div className="galleryGroupName">
          {this.props.galleryName} (#{numberOfPhotos} slik)
        </div>
        <div
          className="galleryGroupThumbnail"
          onClick={() => {
            openGalleryModal(this.props.galleryName, store.dispatch);
          }}
        >
          <div className="galleryBorder" />
          <img className="galleryPhotoThumbnail" src={thumbnailUrl} alt="thumbnail of gallery" />
        </div>
      </div>
    );
  }
}

export default withGalleries(GalleryGroup);
