import React, { Component } from "react";

class ScrollLink extends Component {
  render() {
    const { children, height = 0, onClick = null, transition, className } = this.props;

    return (
      <div
        className={className}
        onClick={() => {
          onClick && onClick();
          height &&
            window.scrollTo({
              top: height,
              left: 0,
              behavior: "smooth",
            });
        }}
        style={{ transition }}
      >
        {children}
      </div>
    );
  }
}

export default ScrollLink;
