const collections = {
  NEWS: "news",
  CONTACT: "contact",
  PRICELIST: "pricelist",
  INTRODUCTION: "introduction",
  EVENTS: "events",
  BUTTON: "applyButton",
  CALENDAR_EVENTS: "calendarEvents",
};

export default collections;
