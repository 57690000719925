import React, { Component } from "react";
import withCollections from "higherOrderComponents/withCollections";

class ContactSection extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="col-lg-8 leftSide">
          <div className="zemljevid">
            <img src="assets/lokacija.png" alt="Lokacija" className="lokacija" />
          </div>
        </div>
        <div className="col-lg-4 rightSide">
          {this.props.collections.contact &&
            Object.keys(this.props.collections.contact).map((singleContact, idx) => {
              return (
                <React.Fragment key={idx}>
                  {idx === 0 ? (
                    ""
                  ) : (
                    <div className="vmesno">
                      <i className="fas fa-ellipsis-h"></i>
                    </div>
                  )}
                  <div className="podatki">
                    <div className="oseba">
                      <i className="far fa-user"></i>
                      {this.props.collections.contact[singleContact].name}
                    </div>
                    <div className="telefonWrapper">
                      <i className="fas fa-phone"></i>
                      {this.props.collections.contact[singleContact].phone}
                    </div>
                    <div className="mailWrapper">
                      <i className="fas fa-inbox"></i>
                      {this.props.collections.contact[singleContact].email}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}

export default withCollections(ContactSection);
