import store from "redux/store";

import firebase from "firebase";
import collections from "constants/collections";
import { addDocumentToCollection } from "redux/actions";

function pullNews() {
  return firebase
    .firestore()
    .collection(collections.NEWS)
    .orderBy("publishedDate", "desc")
    .limit(2)
    .get()
    .then(newsSnapshot => {
      newsSnapshot.forEach(singleNews => {
        addDocumentToCollection(singleNews.data(), singleNews.id, collections.NEWS, store.dispatch);
      });
    });
}

export default pullNews;
