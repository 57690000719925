import React, { Component } from "react";
import withCollections from "higherOrderComponents/withCollections";

class ApplyBtn extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let btnId = Object.keys(this.props.collections.applyButton)[0];
    let btnData = this.props.collections.applyButton[btnId];

    if (!btnData || (btnData && !btnData.shouldShow)) {
      return <></>
    }

    return (
      <a className="applyBtn" target="_blank" rel="noopener noreferrer" href={btnData && btnData.urlPrijavnica}>
        {btnData && btnData.text}
      </a>
    );
  }
}

export default withCollections(ApplyBtn);
