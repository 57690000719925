import React, { Component } from "react";
import withCollections from "higherOrderComponents/withCollections";
import moment from "moment";

const backgroundImage = "assets/tabornikiBackground.jpg";

class NewsCard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="col-12">
        <div className="newsCardImage" style={{ backgroundImage: `url(${backgroundImage})` }}>
          <div className="vmesnik"></div>
          <div className="newsCardWrapper">
            <div className="titleAndDateWrapper">
              <h3 className="newsTitle">{this.props.news.title}</h3>
              <div className="newsPublishedDate">
                {moment(this.props.news.publishedDate.seconds * 1000).format("DD MMM 'YY")}
              </div>
            </div>
            <div className="newsContentWrapper">
              <div className="newsText">{this.props.news.text}</div>
              <div className="newsAuthor">{this.props.news.author}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCollections(NewsCard);
