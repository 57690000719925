import React, { Component } from "react";
import ScrollLink from "components/scrollLink";
import { Link } from "react-router-dom";

class NavbarMobileDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const sectionsHeights = document.getElementsByTagName("section");

    return (
      <div className={`navbarMobileDropdown${this.props.dropdownIsOpened ? `-active` : ``}`}>
        <ScrollLink
          className="navbarMobileLink"
          height={sectionsHeights && sectionsHeights[1] && sectionsHeights[1].offsetTop + 1}
          onClick={this.props.toggleDropdown}
        >
          <div className="navbarMobileItem">
            <img
              src="assets/predstavitev.jpg"
              alt="Predstavitev ikona"
              className="navbarItemIcon"
            />
            <div className="navbarItemName">Predstavitev</div>
          </div>
        </ScrollLink>

        <ScrollLink
          className="navbarMobileLink"
          height={sectionsHeights && sectionsHeights[2] && sectionsHeights[2].offsetTop + 1}
          onClick={this.props.toggleDropdown}
        >
          <div className="navbarMobileItem">
            <img
              src="assets/napovednik.png"
              alt="Napovednik ikona"
              className="navbarItemIcon"
              id="napovednikIcon"
            />
            <div className="navbarItemName">Napovednik</div>
          </div>
        </ScrollLink>

        <ScrollLink
          className="navbarMobileLink"
          height={sectionsHeights && sectionsHeights[3] && sectionsHeights[3].offsetTop + 1}
          onClick={this.props.toggleDropdown}
        >
          <div className="navbarMobileItem">
            <img
              src="assets/novice.jpg"
              alt="Novice ikona"
              className="navbarItemIcon"
              id="noviceIcon"
            />
            <div className="navbarItemName">Novice</div>
          </div>
        </ScrollLink>

        <ScrollLink
          className="navbarMobileLink"
          height={sectionsHeights && sectionsHeights[4] && sectionsHeights[4].offsetTop + 1}
          onClick={this.props.toggleDropdown}
        >
          <div className="navbarMobileItem">
            <img
              src="assets/dogodki.jpg"
              alt="Dogodki ikona"
              className="navbarItemIcon"
              id="dogodkiIcon"
            />
            <div className="navbarItemName">Dogodki</div>
          </div>
        </ScrollLink>

        <ScrollLink
          className="navbarMobileLink"
          height={sectionsHeights && sectionsHeights[5] && sectionsHeights[5].offsetTop + 1}
          onClick={this.props.toggleDropdown}
        >
          <div className="navbarMobileItem">
            <img src="assets/ponudba.jpg" alt="Ponudba ikona" className="navbarItemIcon" />
            <div className="navbarItemName">Ponudba</div>
          </div>
        </ScrollLink>

        <ScrollLink
          className="navbarMobileLink"
          height={sectionsHeights && sectionsHeights[6] && sectionsHeights[6].offsetTop + 1}
          onClick={this.props.toggleDropdown}
        >
          <div className="navbarMobileItem">
            <img
              src="assets/kontakt.jpg"
              alt="Kontakt ikona"
              className="navbarItemIcon"
              id="kontaktIcon"
            />
            <div className="navbarItemName">Kontakt</div>
          </div>
        </ScrollLink>
        <Link className="navbarMobileLink" to="/galerija">
          <div className="navbarMobileItem">
            <img src="assets/galerija.jpg" alt="Galerija ikona" className="navbarItemIcon" />
            <div className="navbarItemName">Galerija</div>
          </div>
        </Link>
      </div>
    );
  }
}

export default NavbarMobileDropdown;
