import store from "redux/store";

import firebase from "firebase";
import collections from "constants/collections";
import { addDocumentToCollection } from "redux/actions";

function pullIntroduction() {
  return firebase
    .firestore()
    .collection(collections.INTRODUCTION)
    .get()
    .then(introductionSnapshot => {
      introductionSnapshot.forEach(introductionPart => {
        addDocumentToCollection(
          introductionPart.data(),
          introductionPart.id,
          collections.INTRODUCTION,
          store.dispatch
        );
      });
    });
}

export default pullIntroduction;
