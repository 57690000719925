import React from "react";

import CalendarEvent from "./CalendarEvent";

const EventsList = ({ events, selectedDate }) => {
  return (
    <div className="eventsListWrapper">
      <div className="eventsListTitle">{selectedDate.format("DD MMM 'YY")}</div>
      <div className="eventsList">
        {events
          .filter(({ from, to }) =>
            selectedDate.isBetween(from, to, "day", "[]")
          )
          .map((event, i) => (
            <CalendarEvent key={i} event={event} />
          ))}
      </div>
    </div>
  );
};

export default EventsList;
