import React, { Component } from "react";
import withCollections from "higherOrderComponents/withCollections";

class HomeIntroduction extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="col-12">
        <div className="introductionContent">
          <div className="introductionRodSotocjeHistory">
            {this.props.collections.introduction &&
              this.props.collections.introduction.historyRodSotocje &&
              this.props.collections.introduction.historyRodSotocje.description}
          </div>
          <div className="introductionWhatIsTabornistvo">
            {this.props.collections.introduction &&
              this.props.collections.introduction.whatIsTabornistvo &&
              this.props.collections.introduction.whatIsTabornistvo.description}
          </div>
          <div className="introductionOverallHistory">
            {this.props.collections.introduction &&
              this.props.collections.introduction.historyOverall &&
              this.props.collections.introduction.historyOverall.description}
          </div>
          <div className="introductionPossibleActivities">
            <div className="introductionAktivnosti">Možne aktivnosti:</div>

            <ul>
              {this.props.collections.introduction &&
                this.props.collections.introduction.possibilities &&
                this.props.collections.introduction.possibilities.activities &&
                this.props.collections.introduction.possibilities.activities.map(
                  (activity, idx) => {
                    return <li key={idx}>{activity}</li>;
                  }
                )}
            </ul>
            <div className="introductionIcons">
              <img src="assets/nogomet.jpg" alt="otroci ikona" className="introductionImage" />
              <img
                src="assets/lokostrelstvo.jpg"
                alt="otroci ikona"
                className="introductionImage"
              />
              <img src="assets/crnimoz.jpg" alt="otroci ikona" className="introductionImage" />
              <img src="assets/menina.jpg" alt="otroci ikona" className="introductionImage" />
              <img src="assets/konji.jpg" alt="otroci ikona" className="introductionImage" />
            </div>
          </div>
          <div className="introductionPlacePossibilities">
            <div className="introductionProstor">Namembnost prostora:</div>
            <ul>
              {this.props.collections.introduction &&
                this.props.collections.introduction.possibilities &&
                this.props.collections.introduction.possibilities.place &&
                this.props.collections.introduction.possibilities.place.map(
                  (placePossibility, idx) => {
                    return <li key={idx}>{placePossibility}</li>;
                  }
                )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withCollections(HomeIntroduction);
