import React, { Component } from "react";
import withGalleries from "higherOrderComponents/withGalleries";

import { closeGalleryModal, setNewPhotoInModal } from "redux/actions/modalActions";
import store from "redux/store";

class GalleryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleKey = event => {
    if (this.props.modal.isModalOpen) {
      if (event.keyCode === 37) {
        // prev photo
        this.setNewPhoto(this.props.modal.photoIdx - 1);
      }

      if (event.keyCode === 39) {
        // next photo
        this.setNewPhoto(this.props.modal.photoIdx + 1);
      }

      if (event.keyCode === 27) {
        // close modal
        closeGalleryModal(store.dispatch);
      }
    }
  };

  componentDidMount() {
    document.addEventListener("keyup", this.handleKey);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKey);
  }

  setNewPhoto = newIdx => {
    let gallery = Object.keys(this.props.galleries[this.props.modal.galleryName]);
    let numberOfPhotos = gallery.length;
    let indexOfPhoto;
    if (newIdx < 0) {
      indexOfPhoto = numberOfPhotos - 1;
    } else if (newIdx >= numberOfPhotos) {
      indexOfPhoto = 0;
    } else {
      indexOfPhoto = newIdx;
    }
    setNewPhotoInModal(indexOfPhoto, store.dispatch);
  };

  render() {
    let photoUrl = "";
    if (this.props.modal.isModalOpen) {
      let photoName = Object.keys(this.props.galleries[this.props.modal.galleryName])[
        this.props.modal.photoIdx
      ];
      photoUrl = this.props.galleries[this.props.modal.galleryName][photoName];
    }

    return (
      <div className={`galleryModal${this.props.modal.isModalOpen ? "-active" : ""}`}>
        <div className="galleryOverlay" onClick={() => closeGalleryModal(store.dispatch)} />
        <div className="closeModalBtn" onClick={() => closeGalleryModal(store.dispatch)}>
          <img
            className="closeBtnImg"
            alt="close modal"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNTIiIGhlaWdodD0iNTIiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTE0My43ODEyNSwxMjkuOTMwMjlsLTEzLjg3NjgsMTMuODUwOTZjLTIuNTMyNDYsMi41NTgyOSAtNi42NjcwNywyLjU1ODI5IC05LjIyNTM2LDBsLTM0LjY3OTA5LC0zNC42NTMyNGwtMzQuNjUzMjQsMzQuNjUzMjRjLTIuNTU4MjksMi41NTgyOSAtNi43MTg3NSwyLjU1ODI5IC05LjI1MTIxLDBsLTEzLjg3NjgsLTEzLjg1MDk2Yy0yLjU1ODI5LC0yLjU1ODI5IC0yLjU1ODI5LC02LjY5MjkxIDAsLTkuMjUxMmwzNC42NTMyNCwtMzQuNjc5MDlsLTM0LjY1MzI0LC0zNC42NTMyNGMtMi41MzI0NSwtMi41ODQxMyAtMi41MzI0NSwtNi43NDQ1OSAwLC05LjI1MTIxbDEzLjg3NjgsLTEzLjg3NjhjMi41MzI0NiwtMi41NTgyOSA2LjY5MjkxLC0yLjU1ODI5IDkuMjUxMjEsMGwzNC42NTMyNCwzNC42NzkwOWwzNC42NzkwOSwtMzQuNjc5MDljMi41NTgyOSwtMi41NTgyOSA2LjcxODc1LC0yLjU1ODI5IDkuMjI1MzYsMGwxMy44NzY4LDEzLjg1MDk2YzIuNTU4MjksMi41NTgyOSAyLjU1ODI5LDYuNzE4NzUgMC4wMjU4NCw5LjI3NzA0bC0zNC42NzkwOCwzNC42NTMyNGwzNC42NTMyNCwzNC42NzkwOWMyLjU1ODI5LDIuNTU4MjkgMi41NTgyOSw2LjY5MjkgMCw5LjI1MTJ6Ij48L3BhdGg+PC9nPjwvZz48L3N2Zz4="
          />
        </div>
        <div className="leftAndRightButtonsGallery">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="48"
            height="48"
            viewBox="0 0 172 172"
            style={{ fill: "#000000" }}
            className="leftButtonGallery"
            onClick={() => {
              this.setNewPhoto(this.props.modal.photoIdx - 1);
            }}
          >
            <g
              fill="none"
              fillRule="nonzero"
              stroke="none"
              strokeWidth="1"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeDasharray=""
              strokeDashoffset="0"
              fontFamily="none"
              fontWeight="none"
              fontSize="none"
              textAnchor="none"
              style={{ mixBlendMode: "normal" }}
            >
              <path d="M0,172v-172h172v172z" fill="none"></path>
              <g fill="#ffffff">
                <path d="M89.66732,7.16667l-39.50065,78.83333l39.50065,78.83333h32.16601l-39.50065,-78.83333l39.50065,-78.83333z"></path>
              </g>
            </g>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="48"
            height="48"
            viewBox="0 0 172 172"
            style={{ fill: "#000000" }}
            className="rightButtonGallery"
            onClick={() => {
              this.setNewPhoto(this.props.modal.photoIdx + 1);
            }}
          >
            <g
              fill="none"
              fillRule="nonzero"
              stroke="none"
              strokeWidth="1"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeDasharray=""
              strokeDashoffset="0"
              fontFamily="none"
              fontWeight="none"
              fontSize="none"
              textAnchor="none"
              style={{ mixBlendMode: "normal" }}
            >
              <path d="M0,172v-172h172v172z" fill="none"></path>
              <g fill="#ffffff">
                <path d="M50.16667,7.16667l39.50065,78.83333l-39.50065,78.83333h32.16601l39.50065,-78.83333l-39.50065,-78.83333z"></path>
              </g>
            </g>
          </svg>
        </div>
        <img src={photoUrl} alt="gallery" className="galleryPhoto" />
        <div className="currentActivePhoto">
          {this.props.modal.photoIdx + 1}/
          {this.props.modal.isModalOpen &&
            Object.keys(this.props.galleries[this.props.modal.galleryName]).length}
        </div>
      </div>
    );
  }
}

export default withGalleries(GalleryModal);
