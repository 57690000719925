import collections from "constants/collections";

let initialState = {
  [collections.NEWS]: {},
  [collections.CONTACT]: {},
  [collections.PRICELIST]: {},
  [collections.INTRODUCTION]: {},
  [collections.EVENTS]: {},
  [collections.BUTTON]: {},
  [collections.CALENDAR_EVENTS]: {},
};

export default function collectionsReducer(state, action) {
  if (state === undefined) {
    return initialState;
  }

  let newState = Object.assign({}, state);

  switch (action.type) {
    case "ADD_DOCUMENT_TO_COLLECTION": {
      newState[action.collectionName] = Object.assign(
        {},
        state[action.collectionName]
      );
      newState[action.collectionName] = {
        ...newState[action.collectionName],
        [action.documentId]: action.document,
      };
      break;
    }

    default:
      break;
  }

  return newState;
}
