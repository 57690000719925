import React, { Component } from "react";
import withGalleries from "higherOrderComponents/withGalleries";
import GalleryGroup from "components/galleryGroup";
import GalleryModal from "components/modals/galleryModal";
import pullGalleries from "api/firestore/galleries";

let initialState = {
  galleryName: "",
  galleryIsOpen: false,
};

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount = () => {
    if (Object.keys(this.props.galleries).length === 0) {
      pullGalleries();
    }
  };

  backToHome = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <div className="container">
        <GalleryModal />
        <div className="backToHomeBtn" onClick={this.backToHome}>
          <i className="fas fa-arrow-left"></i>
          <div className="backToHomeText">Nazaj na domačo stran</div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2 className="galleryTitle">Galerija</h2>
          </div>
          {Object.keys(this.props.galleries).map((galleryId, idx) => {
            return (
              <div className="col-lg-4 col-md-6 col-sm-12" key={idx}>
                <GalleryGroup galleryName={galleryId} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withGalleries(Gallery);
