export function openGalleryModal(galleryName, dispatch) {
  dispatch({
    type: "OPEN_GALLERY_MODAL",
    galleryName,
  });
}

export function closeGalleryModal(dispatch) {
  dispatch({
    type: "CLOSE_GALLERY_MODAL",
  });
}

export function setNewPhotoInModal(indexOfPhoto, dispatch) {
  dispatch({
    type: "SET_NEW_PHOTO_IN_MODAL",
    indexOfPhoto,
  });
}
