import store from "redux/store";

import firebase from "firebase";
import collections from "constants/collections";
import { addDocumentToCollection } from "redux/actions";

function pullApplyButton() {
  return firebase
    .firestore()
    .collection(collections.BUTTON)
    .get()
    .then((buttonSnapshot) => {
      buttonSnapshot.forEach((buttonData) => {
        addDocumentToCollection(
          buttonData.data(),
          buttonData.id,
          collections.BUTTON,
          store.dispatch
        );
      });
    });
}

export default pullApplyButton;
