import React, { Component } from "react";

import withCollections from "higherOrderComponents/withCollections";
import HomeHero from "components/homeHero/homeHero";
import NavbarAbsolute from "components/navbar/navbarAbsolute/navbarAbsolute";
import NavbarFixed from "components/navbar/navbarFixed/navbarFixed";
import HomeIntroduction from "components/homeIntroduction/homeIntroduction";
import NewsCard from "components/cards/newsCard/newsCard";
import Footer from "components/footer/footer";
import EventCard from "components/cards/eventCard/eventCard";
import ContactSection from "components/contactSection";
import PricelistSection from "components/pricelistSection";
import NavbarMobile from "components/navbar/navbarMobile";
import CalendarSection from "components/calendar/CalendarSection";

import navbar from "utils/navbar";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idOfActiveEvent: 0,
      newPhotoInEvents: true,
      shouldDisplayFixedNavbar: false,
      indexOfActiveFixedNavbarItem: 0,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScrollNavbar);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollNavbar);
  }

  setNewPhotoToFalse = () => {
    this.setState({ ...this.state, newPhotoInEvents: false });
  };

  handleScrollNavbar = () => {
    const shouldDisplayFixedNavbar = navbar.shouldDisplayFixedNavbar();
    const indexOfActiveFixedNavbarItem = navbar.getIndexOfActiveNavbarItem();

    if (indexOfActiveFixedNavbarItem !== this.state.indexOfActiveFixedNavbarItem) {
      this.setState({ indexOfActiveFixedNavbarItem }, () => {
        if (shouldDisplayFixedNavbar !== this.state.shouldDisplayFixedNavbar) {
          this.setState({ shouldDisplayFixedNavbar });
        }
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <NavbarAbsolute />
        <NavbarFixed
          shouldDisplayFixedNavbar={this.state.shouldDisplayFixedNavbar}
          indexOfActiveFixedNavbarItem={this.state.indexOfActiveFixedNavbarItem}
        />
        <NavbarMobile />
        <section className="heroSection">
          <div className="container">
            <div className="row">
              <HomeHero />
            </div>
          </div>
        </section>
        <section className="introductionSection">
          <div className="container">
            <div className="row">
              <div className="introductionWrapper">
                <div className="col-12">
                  <h2 id="predstavitev" className="sectionTitle">
                    Predstavitev taborniškega društva
                  </h2>
                  <img src="assets/grb.png" alt="Grb ikona" className="introductionIconTitle" />
                </div>
                <HomeIntroduction />
              </div>
            </div>
          </div>
        </section>
        <div className="pika">&#8226;</div>
        <section className="calendarSection">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="sectionTitle">Napovednik dogodkov</h2>
              </div>
              <div className="calendarWrapper">
                <CalendarSection />
              </div>
            </div>
          </div>
        </section>
        <div className="pika">&#8226;</div>
        <section className="newsSection">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 id="novice" className="sectionTitle">
                  Novice
                </h2>
              </div>
              {this.props.collections.news &&
                Object.keys(this.props.collections.news).map((newsId, index) => {
                  const singleNews = this.props.collections.news[newsId];
                  return <NewsCard news={singleNews} key={index} />;
                })}
            </div>
          </div>
        </section>
        <div className="pika1">&#8226;</div>
        <section className="eventSection">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 id="sectionTitleDogodki" className="sectionTitle">
                  Dogodki
                </h2>
              </div>
              <div className="eventsWrapper">
                <div
                  className="leftArrowEvents"
                  onClick={() => {
                    if (this.state.idOfActiveEvent > 0) {
                      this.setState({
                        idOfActiveEvent: this.state.idOfActiveEvent - 1,
                        newPhotoInEvents: true,
                      });
                    } else {
                      this.setState({
                        idOfActiveEvent: Object.keys(this.props.collections.events).length - 1,
                        newPhotoInEvents: true,
                      });
                    }
                  }}
                >
                  <img src="assets/levo.png" alt="Ikona levo" className="eventLevo" />
                </div>
                <EventCard
                  event={
                    this.props.collections.events[
                      Object.keys(this.props.collections.events)[this.state.idOfActiveEvent]
                    ]
                  }
                  newPhotoInEvents={this.state.newPhotoInEvents}
                  setNewPhotoToFalse={this.setNewPhotoToFalse}
                />
                <div
                  className="rightArrowEvents"
                  onClick={() => {
                    if (
                      this.state.idOfActiveEvent ===
                      Object.keys(this.props.collections.events).length - 1
                    ) {
                      this.setState({ idOfActiveEvent: 0, newPhotoInEvents: true });
                    } else {
                      this.setState({
                        idOfActiveEvent: this.state.idOfActiveEvent + 1,
                        newPhotoInEvents: true,
                      });
                    }
                  }}
                >
                  <img src="assets/desno.png" alt="Ikona desno" className="eventDesno" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="pika2">&#8226;</div>
        <section className="pricelistSection">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="sectionTitle">Ponudba</h2>
              </div>
              <PricelistSection />
            </div>
          </div>
        </section>
        <div className="pika3">&#8226;</div>
        <section className="contactSection">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="sectionTitle">Kontakt</h2>
              </div>
              <ContactSection />
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withCollections(Home);
