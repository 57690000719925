import { combineReducers } from "redux";

import collectionsReducer from "./collectionsReducer";
import galleryReducer from "./galleryReducer";
import modalReducer from "./modalReducer";

const rootReducer = combineReducers({
  collections: collectionsReducer,
  galleries: galleryReducer,
  modal: modalReducer,
});

export default rootReducer;
