let initialState = {};

export default function galleryReducer(state, action) {
  if (state === undefined) {
    return initialState;
  }

  let newState = Object.assign({}, state);

  switch (action.type) {
    case "ADD_PHOTO_TO_GALLERY": {
      newState[action.galleryName] = Object.assign({}, state[action.galleryName]);
      newState[action.galleryName] = {
        ...newState[action.galleryName],
        [action.photoId]: action.photoUrl,
      };
      break;
    }

    default:
      break;
  }

  return newState;
}
