import React, { Component } from "react";
import ScrollLink from "components/scrollLink";
import { Link } from "react-router-dom";

class NavbarAbsolute extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const sectionsHeights = document.getElementsByTagName("section");

    return (
      <div className="navbarAbsolute">
        <div className="navbarLevo">
          <ScrollLink className="navbarLink" height={-1}>
            <div className="navbarItem" id="navbarItemLogo">
              <div className="navbarLevoPostavitev">
                <img src="assets/grb.png" alt="Grb Ikona" className="navbarItemIcon" id="grbIcon" />
                {/* <div className="navbarItemNameRODinNazarje">
                  <div className="navbarItemName" id="navbarItemNameROD">
                    ROD SOTOČJE
                  </div>
                  <div className="navbarItemName" id="navbarItemNameNazarje">
                    Nazarje
                  </div>
                </div> */}
              </div>
            </div>
          </ScrollLink>
        </div>

        <div className="navbarDesno">
          <ScrollLink
            className="navbarLink"
            height={sectionsHeights && sectionsHeights[1] && sectionsHeights[1].offsetTop + 1}
          >
            <div className="navbarItem">
              <img
                src="assets/predstavitev.jpg"
                alt="Predstavitev ikona"
                className="navbarItemIcon"
              />
              <div className="navbarItemName">Predstavitev</div>
            </div>
          </ScrollLink>

          <ScrollLink
            className="navbarLink"
            height={sectionsHeights && sectionsHeights[2] && sectionsHeights[2].offsetTop + 1}
          >
            <div className="navbarItem">
              <img
                src="assets/napovednik.png"
                alt="Napovednik ikona"
                className="navbarItemIcon"
                id="napovednikIcon"
              />
              <div className="navbarItemName">Napovednik</div>
            </div>
          </ScrollLink>

          <ScrollLink
            className="navbarLink"
            height={sectionsHeights && sectionsHeights[3] && sectionsHeights[3].offsetTop + 1}
          >
            <div className="navbarItem">
              <img
                src="assets/novice.jpg"
                alt="Novice ikona"
                className="navbarItemIcon"
                id="noviceIcon"
              />
              <div className="navbarItemName">Novice</div>
            </div>
          </ScrollLink>

          <ScrollLink
            className="navbarLink"
            height={sectionsHeights && sectionsHeights[4] && sectionsHeights[4].offsetTop + 1}
          >
            <div className="navbarItem">
              <img
                src="assets/dogodki.jpg"
                alt="Dogodki ikona"
                className="navbarItemIcon"
                id="dogodkiIcon"
              />
              <div className="navbarItemName">Dogodki</div>
            </div>
          </ScrollLink>

          <ScrollLink
            className="navbarLink"
            height={sectionsHeights && sectionsHeights[5] && sectionsHeights[5].offsetTop + 1}
          >
            <div className="navbarItem">
              <img src="assets/ponudba.jpg" alt="Ponudba ikona" className="navbarItemIcon" />
              <div className="navbarItemName">Ponudba</div>
            </div>
          </ScrollLink>

          <ScrollLink
            className="navbarLink"
            height={sectionsHeights && sectionsHeights[6] && sectionsHeights[6].offsetTop + 1}
          >
            <div className="navbarItem">
              <img
                src="assets/kontakt.jpg"
                alt="Kontakt ikona"
                className="navbarItemIcon"
                id="kontaktIcon"
              />
              <div className="navbarItemName">Kontakt</div>
            </div>
          </ScrollLink>
          <Link className="navbarLink" to="/galerija">
            <div className="navbarItem">
              <img src="assets/galerija.jpg" alt="Galerija ikona" className="navbarItemIcon" />
              <div className="navbarItemName">Galerija</div>
            </div>
          </Link>

          <a href="https://www.facebook.com/rod.sotocje" target="_blank" rel="noopener noreferrer">
            <img
              id="facebookIcon"
              src="assets/facebook.jpg"
              alt="Facebook ikona"
              className="navbarItemIcon"
            />
          </a>

          <a href="https://www.instagram.com/rodsotocje/" target="_blank" rel="noopener noreferrer">
            <img
              id="instagramIcon"
              src="assets/instagram.jpg"
              alt="Instagram ikona"
              className="navbarItemIcon"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default NavbarAbsolute;
