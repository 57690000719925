import store from "redux/store";

import firebase from "firebase";
import collections from "constants/collections";
import { addDocumentToCollection } from "redux/actions";

function pullCalendarEvents() {
  return firebase
    .firestore()
    .collection(collections.CALENDAR_EVENTS)
    .get()
    .then((calendarEventsSnapshot) => {
      calendarEventsSnapshot.forEach((singleCalendarEvent) => {
        addDocumentToCollection(
          singleCalendarEvent.data(),
          singleCalendarEvent.id,
          collections.CALENDAR_EVENTS,
          store.dispatch
        );
      });
    });
}

export default pullCalendarEvents;
