import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

const withGalleries = Component => {
  class WithGalleries extends React.Component {
    render() {
      return <Component {...this.props} />;
    }
  }
  const mapStateToProps = state => {
    return { galleries: state.galleries, modal: state.modal };
  };

  return compose(
    connect(
      mapStateToProps,
      null
    )
  )(WithGalleries);
};

export default withGalleries;
