import React, { Component } from "react";
import ApplyBtn from "components/applyBtn/applyBtn";

const backgroundImage = "assets/naslovnica.jpg";

class HomeHero extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="heroWrapper">
        <ApplyBtn />
        <div
          className="backgroundImageHero"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        />
        <div className="container">
          <div className="heroContainer">
            <div className="titleBackground">
              <div className="heroTitle">ROD Sotočje</div>
              <hr className="line" />
              <div className="heroSubTitle">Taborniško društvo Nazarje</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeHero;
