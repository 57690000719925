import React, { Component } from "react";
import { Switch, Route, Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import CookieConsent from "react-cookie-consent";

import Home from "views/home";
import Gallery from "views/gallery";

import routes from "constants/routes";
import pullNews from "api/firestore/news";
import pullContact from "api/firestore/contact";
import pullPricelist from "api/firestore/pricelist";
import pullIntroduction from "api/firestore/introduction";
import pullEvents from "api/firestore/events";
import pullApplyButton from "api/firestore/applyButton";
import pullCalendarEvents from "api/firestore/calendar";

const hist = createBrowserHistory();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    window.scrollTo({ top: 0 });

    pullNews();
    pullContact();
    pullPricelist();
    pullIntroduction();
    pullEvents();
    pullApplyButton();
    pullCalendarEvents();
  }

  render() {
    return (
      <Router history={hist}>
        <CookieConsent
          location="bottom"
          cookieName="rodSotocjeCookie"
          acceptOnScroll={true}
          acceptOnScrollPercentage={25}
          expires={180}
          buttonText="Strinjam se"
          buttonClasses={"onCookieBtnHover cookieAcceptColors"}
          enableDeclineButton={true}
          declineButtonText={<i className="fas fa-times"></i>}
          declineButtonClasses={"onCookieBtnHover cookieDeclineColors"}
          flipButtons={true}
        >
          Spletno mesto uporablja piškotke. Z nadaljnjo uporabo spletnega mesta soglašate s
          piškotki.
        </CookieConsent>
        <Switch>
          <Route exact path={routes.HOME} component={Home} />
          <Route exact path={routes.GALLERY} component={Gallery} />
        </Switch>
      </Router>
    );
  }
}

export default App;
