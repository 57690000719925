import React, { useState } from "react";
import moment from "moment";
import Calendar from "react-calendar";

import EventsList from "./EventsList";

import withCollections from "higherOrderComponents/withCollections";

import "react-calendar/dist/Calendar.css";

const CalendarSection = ({ collections }) => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const { calendarEvents } = collections;
  const parsedCalendarEvents = Object.values(calendarEvents)
    .map((calendarEvent) => ({
      ...calendarEvent,
      from: moment(calendarEvent.from.toDate()),
      to: moment(calendarEvent.to.toDate()),
    }))
    .sort((first, second) => first.from.valueOf() - second.from.valueOf());

  return (
    <div className="calendar">
      <Calendar
        value={selectedDate.toDate()}
        onChange={(value, event) => setSelectedDate(moment(value))}
        tileClassName={({ activeStartDate, date, view }) => {
          return view === "month" &&
            parsedCalendarEvents.some(({ from, to }) =>
              moment(date).isBetween(from, to, "day", "[]")
            )
            ? "available-date"
            : "";
        }}
      />
      <EventsList events={parsedCalendarEvents} selectedDate={selectedDate} />
    </div>
  );
};

export default withCollections(CalendarSection);
