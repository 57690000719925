const firebaseConfig = {
  apiKey: "AIzaSyB17rFQG80UGpAV9gjrFHiUXyeEHmv_S0A",
  authDomain: "rodsotocje-dff2b.firebaseapp.com",
  databaseURL: "https://rodsotocje-dff2b.firebaseio.com",
  projectId: "rodsotocje-dff2b",
  storageBucket: "rodsotocje-dff2b.appspot.com",
  messagingSenderId: "718822297212",
  appId: "1:718822297212:web:024c8376302c5127",
  measurementId: "G-CRE6X59DSV",
};

export default firebaseConfig;
