import store from "redux/store";

import firebase from "firebase";
import { addPhotoToGallery } from "redux/actions";

import firebaseStorage from "constants/storage";

function pullGalleries() {
  return firebase
    .storage()
    .ref()
    .child(`${firebaseStorage.galleriesFolder}`)
    .listAll()
    .then(allGalleries => {
      allGalleries.prefixes.forEach(gallery => {
        firebase
          .storage()
          .ref()
          .child(`${firebaseStorage.galleriesFolder}/${gallery.name}`)
          .listAll()
          .then(allPhotosInGallery => {
            allPhotosInGallery.items.forEach(photoInGallery => {
              firebase
                .storage()
                .ref()
                .child(`${firebaseStorage.galleriesFolder}/${gallery.name}/${photoInGallery.name}`)
                .getDownloadURL()
                .then(photoUrl => {
                  addPhotoToGallery(photoInGallery.name, photoUrl, gallery.name, store.dispatch);
                });
            });
          });
      });
    });
}

export default pullGalleries;
