import store from "redux/store";

import firebase from "firebase";
import collections from "constants/collections";
import { addDocumentToCollection } from "redux/actions";

function pullContact() {
  return firebase
    .firestore()
    .collection(collections.CONTACT)
    .get()
    .then(contactSnapshot => {
      contactSnapshot.forEach(singleContact => {
        addDocumentToCollection(
          singleContact.data(),
          singleContact.id,
          collections.CONTACT,
          store.dispatch
        );
      });
    });
}

export default pullContact;
