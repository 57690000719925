import React, { Component } from "react";
import withCollections from "higherOrderComponents/withCollections";

class PricelistSection extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="col-12">
        <div className="cenik">
          <div className="cenikWrapper">
            <div className="hiskaPiknikSpodaj">
              <div className="piknik">
                <i className="fas fa-sun"></i>
                Piknik prostor in spodnje nadstropje hiške{" "}
                {this.props.collections.pricelist &&
                  this.props.collections.pricelist.Cenik &&
                  this.props.collections.pricelist.Cenik.hiskaPiknik}
              </div>
            </div>
            <div className="hiskaSamoZgoraj">
              <div className="spanje">
                <i className="fas fa-sun"></i>
                Spanje v zgornjem nadstropju hiške{" "}
                {this.props.collections.pricelist &&
                  this.props.collections.pricelist.Cenik &&
                  this.props.collections.pricelist.Cenik.hiskaZgoraj}
              </div>
            </div>
            <div className="hiskaTaborjenje">
              <div className="taborjenje">
                <i className="fas fa-sun"></i>
                Taborjenje{" "}
                {this.props.collections.pricelist &&
                  this.props.collections.pricelist.Cenik &&
                  this.props.collections.pricelist.Cenik.taborjenje}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <img src="assets/lazeHiska1.jpg" alt="Hiska" className="hiska" />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <img src="assets/lazeHiska2.jpg" alt="Hiska" className="hiska" />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <img src="assets/lazeHiska3.jpg" alt="Hiska" className="hiska" />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <img src="assets/lazeHiska4.jpg" alt="Hiska" className="hiska" />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <img src="assets/lazeHiska6.jpg" alt="Hiska" className="hiska" />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <img src="assets/lazeHiska10.jpg" alt="Hiska" className="hiska" />
          </div>
        </div>
      </div>
    );
  }
}

export default withCollections(PricelistSection);
