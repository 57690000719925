import React, { Component } from "react";
import ScrollLink from "components/scrollLink";
import NavbarMobileDropdown from "../navbarMobileDropdown";

class NavbarMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownIsOpened: false,
    };
  }

  toggleDropdown = () => {
    this.setState({ dropdownIsOpened: !this.state.dropdownIsOpened });
  };

  render() {
    return (
      <div className="navbarMobile">
        <ScrollLink height={-1} className="navbarMobileLeft">
          <img src="assets/grb.png" alt="Grb Ikona" className="navbarMobileLogo" />
        </ScrollLink>
        <div className="navbarMobileRight">
          <div>
            <a
              href="https://www.facebook.com/rod.sotocje"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="assets/facebook.jpg" alt="Facebook ikona" className="navbarMobileIcon" />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/rodsotocje/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="assets/instagram.jpg" alt="Instagram ikona" className="navbarMobileIcon" />
            </a>
          </div>
          <div className="navbarMobileDropdownBtn">
            <i className="fas fa-bars fa-2x burgerIcon" onClick={this.toggleDropdown}></i>
          </div>
        </div>
        <NavbarMobileDropdown
          toggleDropdown={this.toggleDropdown}
          dropdownIsOpened={this.state.dropdownIsOpened}
        />
      </div>
    );
  }
}

export default NavbarMobile;
