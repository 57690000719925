let initialState = {
  isModalOpen: false,
  galleryName: "",
  photoIdx: 0,
};

export default function modalReducer(state, action) {
  if (state === undefined) {
    return initialState;
  }

  let newState = Object.assign({}, state);

  switch (action.type) {
    case "OPEN_GALLERY_MODAL": {
      newState = Object.assign({}, initialState);
      newState.isModalOpen = true;
      newState.galleryName = action.galleryName;
      break;
    }

    case "CLOSE_GALLERY_MODAL": {
      newState = Object.assign({}, initialState);
      break;
    }

    case "SET_NEW_PHOTO_IN_MODAL": {
      newState.photoIdx = action.indexOfPhoto;
      break;
    }

    default:
      break;
  }

  return newState;
}
