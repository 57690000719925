/**
 * Signal if fixed bar should be displayed or not based on current scroll location
 *
 * @return  {boolean} Variable indicating if navbar should be displayed
 */
function shouldDisplayFixedNavbar() {
  try {
    const scrolledPastFirstSection =
      window.scrollY >= document.getElementsByTagName("section")[1].offsetTop;

    return scrolledPastFirstSection;
  } catch (e) {
    console.error("shouldDisplayNavbar", e);
    return false;
  }
}

function getIndexOfActiveNavbarItem() {
  let idx = 0;
  const sections = document.getElementsByTagName("section");
  for (let i = 0; i < sections.length; i++) {
    if (window.scrollY >= sections[i].offsetTop) {
      idx = i;
    }
  }
  return idx;
}

export default { shouldDisplayFixedNavbar, getIndexOfActiveNavbarItem };
