import store from "redux/store";

import firebase from "firebase";
import collections from "constants/collections";
import { addDocumentToCollection } from "redux/actions";

function pullEvents() {
  return firebase
    .firestore()
    .collection(collections.EVENTS)
    .get()
    .then(eventSnapshot => {
      eventSnapshot.forEach(singleEvent => {
        addDocumentToCollection(
          singleEvent.data(),
          singleEvent.id,
          collections.EVENTS,
          store.dispatch
        );
      });
    });
}

export default pullEvents;
