import store from "redux/store";

import firebase from "firebase";
import collections from "constants/collections";
import { addDocumentToCollection } from "redux/actions";

function pullPricelist() {
  return firebase
    .firestore()
    .collection(collections.PRICELIST)
    .get()
    .then(pricelistSnapshot => {
      pricelistSnapshot.forEach(singlePricelist => {
        addDocumentToCollection(
          singlePricelist.data(),
          singlePricelist.id,
          collections.PRICELIST,
          store.dispatch
        );
      });
    });
}

export default pullPricelist;
