import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="footer">
        <div className="footerLeft">
          <div className="footerCopyrights">
            Vse pravice pridržane &copy; ROD Sotočje Nazarje, September 2019
          </div>
        </div>
        <div className="footerRight">
          <div className="footerAuthors">Žan Pečovnik, Sara Koljić</div>
        </div>
      </div>
    );
  }
}

export default Footer;
