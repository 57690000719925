import React, { Component } from "react";
import firebase from "firebase";
import "firebase/storage";
import firebaseStorage from "constants/storage";

class EventCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoUrl: null,
    };
  }

  render() {
    if (this.props.event && this.props.event.photoId && this.props.newPhotoInEvents) {
      firebase
        .storage()
        .ref()
        .child(`${firebaseStorage.eventsFolder}/${this.props.event.photoId}`)
        .getDownloadURL()
        .then(url => {
          this.setState({ photoUrl: url });
          this.props.setNewPhotoToFalse();
        });
    }

    return (
      <div className="eventCardWrapper">
        <div className="eventTitle">{this.props.event && this.props.event.title}</div>
        {/* <h3 className="eventTitle">{this.props.event && this.props.event.title}</h3> */}
        <div className="eventPhotoDescription">
          <img className="eventPhoto" alt="event" src={this.state.photoUrl} />
          <div className="eventDescription">{this.props.event && this.props.event.description}</div>
        </div>
      </div>
    );
  }
}

export default EventCard;
